import React from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { StickyContainer, Sticky } from 'react-sticky'

import PageHeader from '../../containers/PageHeader'
import PageFooter from '../../containers/PageFooter'

import { goToLandmark } from '../../utils'

import './styles.sass'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, timeToRead, headings } = markdownRemark

  const linkTags = false

  const scrollToOnClick = (e) => {
    const url = e.target.href
    const id = url.substr(url.indexOf('#') + 1) // get ID from hash part of url

    if (e.target && id.length > 0) {
      e.preventDefault()
      goToLandmark(id, 100)
    }
  }

  return (
    <div className={`pagetype-${frontmatter.type}`}>
      <Helmet>
        <title>
          {frontmatter.title} | Blog | Ironstar - Enterprise Drupal Hosting
        </title>
        <meta name="description" content={frontmatter.description} />
        <meta name="keywords" content={frontmatter.keywords} />
      </Helmet>

      <PageHeader />

      <main className="post-container container">
        <header className="page__header">
          <h1 className="h1">{frontmatter.title}</h1>
        </header>
        <StickyContainer>
          <div className="row">
            <aside className="col-lg-3 pb-5">
              <div className="small text-muted">
                {frontmatter.author && (
                  <p>
                    Written by{' '}
                    <span className="font-weight-bold">
                      {frontmatter.author}
                    </span>
                  </p>
                )}
                <p>
                  {frontmatter.date_published && (
                    <span>{frontmatter.date_published}</span>
                  )}
                  {frontmatter.tags &&
                    Array.isArray(frontmatter.tags) &&
                    frontmatter.tags.length > 0 && (
                      <span className="font-italic">
                        <span className="mx-2">&mdash;</span>
                        {frontmatter.tags.map((tag, idx) => {
                          return (
                            <React.Fragment key={`post__tag--${tag}-${idx}`}>
                              {idx > 0 ? ', ' : ''}
                              {linkTags ? (
                                <Link href="/tag/test">{tag}</Link>
                              ) : (
                                tag
                              )}
                            </React.Fragment>
                          )
                        })}
                      </span>
                    )}
                </p>
                {timeToRead && <p>Time to read: {timeToRead} minutes</p>}

                <Sticky disableCompensation>
                  {({ isSticky }) => (
                    <div>
                      {headings && (
                        <div
                          className={['page__toc', isSticky && 'is-sticky']
                            .filter(Boolean)
                            .join(' ')}
                        >
                          <details>
                            <summary className="h6">Table of Contents</summary>
                            <ul>
                              {headings.map((h) => (
                                <li key={`heading--${h.id}`}>
                                  <a
                                    href={`#${h.id}`}
                                    onClick={scrollToOnClick}
                                  >
                                    {h.value}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </details>
                        </div>
                      )}
                    </div>
                  )}
                </Sticky>
              </div>

              {frontmatter.image && frontmatter.showAsideImage !== false && (
                <figure className="page__aside__image">
                  <img
                    src={frontmatter.image}
                    alt={frontmatter.image_alt}
                    title={frontmatter.image_alt}
                  />

                  {frontmatter.image_caption && (
                    <figcaption
                      className="text-monospace"
                      dangerouslySetInnerHTML={{
                        __html: frontmatter.image_caption,
                      }}
                    />
                  )}
                </figure>
              )}
            </aside>
            <section className="col-lg-9 page__content">
              <div
                className="page__content__inner"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </section>
          </div>
        </StickyContainer>
      </main>

      <PageFooter />
    </div>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      timeToRead
      headings(depth: h2) {
        id
        value
      }
      frontmatter {
        date_published(formatString: "MMMM DD, YYYY")
        date_edited(formatString: "MMMM DD, YYYY")
        slug
        title
        description
        keywords
        type
        draft
        author
        tags
        image
        image_alt
        image_caption
        showAsideImage
      }
    }
  }
`
